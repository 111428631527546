import tw, { css } from 'twin.macro'

export const slider = {
  wrapper: tw`flex justify-end mb-8 mt-4`,
  icon: tw`w-8 text-purple`,
  testimonials: tw`flex space-x-8 tablet:flex-col tablet:w-full`,
}

export const content = {
  wrapper: tw`py-10 phablet:px-0 tablet:py-5`,
  headingContainer: {
    base: tw`flex`,
    heading: tw` my-auto text-xl justify-center`,
    img: [
      tw`my-auto mr-3 justify-center`,
      css`
        max-height: 83px;
        max-width: 83px;
      `,
    ],
  },
}
