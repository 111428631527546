import tw, { css } from 'twin.macro'

export const textCol = {
  wrapper: tw`self-center desklet:mr-0`,
  heading: tw`text-xl p-0 m-0`,
  para: tw`mt-1 font-bold text-purple text-sm pb-0`,
  description: tw`my-7`,
  buttons: tw`flex`,
  icon: tw`h-4 w-4 ml-1 inline-block`,
}

export const imageCol = {
  wrapper: tw`flex m-auto py-9 desklet:mt-5`,
  image: tw`object-cover h-[275px] rounded-3xl phablet:w-[275px] phablet:h-[175px]`,
}

export const allContent = tw`grid grid-cols-[auto 375px] gap-x-[5rem] desklet:grid-cols-1`

export const tabbedSlider = {
  wrapper: tw`flex flex-col mx-auto py-10 items-center px-5 desklet:px-0 phone:px-4 tablet:py-5`,
  nav: tw`flex w-full justify-between mb-10 desklet:hidden`,
  content: tw`flex desklet:flex-col-reverse`,
  button: {
    base: tw`text-purple uppercase flex flex-row whitespace-nowrap text-sm m-auto desklet:hidden`,
    responsive: tw`hidden w-min text-purple uppercase m-0 desklet:block`,
    left: [
      tw`flex m-auto justify-center pb-0 w-[20px] desklet:hidden`,
      css`
        transform: rotate(270deg);
      `,
    ],
    right: [
      tw`flex m-auto justify-center w-[20px] desklet:hidden`,
      css`
        transform: rotate(90deg);
      `,
    ],
    icon: tw`h-12 w-12 p-0 m-0`,
  },
}
