import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from '../Container/Container'
import { wrapper, name } from './Testimonial.styles'

function Image({ image }) {
  let imageObject
  let alt = ''

  if (image != null) {
    imageObject = getImage(image.gatsbyImage)
    alt = image.altText
    return <GatsbyImage image={imageObject} alt={alt} css={name.img} imgStyle={{ borderRadius: `100%` }} />
  }
  return null
}

Image.propTypes = {
  image: PropTypes.object,
}

function Testimonial({ image, author, testimonial }) {
  return (
    <Container css={wrapper}>
      <span tw="text-[24px] text-black text-lg">{testimonial}</span>
      <div css={name.base}>
        <Image image={image} />
        <p css={name.text}>{author}</p>
      </div>
    </Container>
  )
}

Testimonial.propTypes = {
  testimonial: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.object,
}

export default Testimonial
