import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ArrowLongRightIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid'
import { textCol, imageCol, tabbedSlider, allContent } from './TabbedSlider.styles'
import Button from '../../components/Button/Button'
import Container from '../../components/Container/Container'
import BackgroundImg from '../../assets/images/green-water-color.png'
import Wysiwyg from '../../components/Wysiwyg'
import ButtonBg from '../../assets/images/button-bg.png'

function TextColumn({ description, ageGroup, careType, title, link }) {
  let parsedLink
  if (link) {
    parsedLink = new URL(link)
  }
  return (
    <div css={textCol.wrapper}>
      <h2 css={textCol.heading}>{title}</h2>
      <p css={textCol.para}>{ageGroup}</p>
      <Wysiwyg css={textCol.description}>{description}</Wysiwyg>
      <p tw="p-0 mb-10">
        <strong>Care type: </strong>
        {careType}
      </p>
      <div css={textCol.buttons}>
        <Button variant="primary" size="large" href={link && parsedLink.pathname}>
          Learn More
        </Button>
        <Button href="/locations" variant="text" tw="ml-5 whitespace-normal text-left text-sm" iconPosition="after" iconLibrary="solid">
          <span>
            or find a facility closest
            <span tw="whitespace-nowrap">
              {' '}
              to me
              <ArrowLongRightIcon css={textCol.icon} />
            </span>
          </span>
        </Button>
      </div>
    </div>
  )
}

function ImageColumn({ image }) {
  const imageObject = getImage(image?.gatsbyImage)
  return (
    <div
      css={imageCol.wrapper}
      style={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        backgroundSize: `contain`,
      }}
    >
      {imageObject && (
        <GatsbyImage
          css={imageCol.image}
          imgStyle={{ objectPosition: `0px 20%`, borderRadius: `1.5rem` }}
          alt={image?.altText}
          image={imageObject}
        />
      )}
    </div>
  )
}

function Slide({ description, ageGroup, careType, title, image, link }) {
  return (
    <Container css={allContent}>
      <TextColumn ageGroup={ageGroup} description={description} careType={careType} title={title} link={link} />
      <ImageColumn image={image} />
    </Container>
  )
}

function TabbedSlider({ slides }) {
  const [current, setCurrent] = useState(0)

  function rightClick() {
    setCurrent(current === slides.length - 1 ? 0 : current + 1)
  }

  function leftClick() {
    setCurrent(current === 0 ? slides.length - 1 : current - 1)
  }

  return (
    <div tw="flex">
      <button onClick={leftClick} tw="pl-5" css={tabbedSlider.button.base} type="submit">
        <p css={tabbedSlider.button.left}>{current !== 0 ? slides[current - 1].title : slides[slides.length - 1].title}</p>
        <ChevronLeftIcon css={tabbedSlider.button.icon} />
      </button>
      <Container variant="wide" css={tabbedSlider.wrapper}>
        <Container css={tabbedSlider.nav}>
          {slides.map((slide, index) => (
            <Button
              onClick={() => setCurrent(index)}
              style={
                current === index
                  ? {
                      backgroundImage: `url(${ButtonBg})`,
                      backgroundRepeat: `no-repeat`,
                      backgroundPosition: `center`,
                      backgroundSize: `contain`,
                      color: `purple`,
                    }
                  : { backgroundImage: `none` }
              }
              tw="uppercase font-proxima p-4 min-w-max flex justify-center items-center "
              key={slide.title}
              variant="text"
            >
              {slide.title}
            </Button>
          ))}
        </Container>
        <div css={tabbedSlider.content}>
          {slides.map((slide, index) => (
            <div key={slide.title}>
              {current === index && (
                <Slide
                  ageGroup={slide.ageGroup}
                  description={slide.description}
                  careType={slide.careType}
                  title={slide.title}
                  image={slide.image}
                  link={slide.link && slide.link.url}
                />
              )}
            </div>
          ))}
          {/* RESPONSIVE BUTTONS */}
          <div tw="hidden desklet:flex w-full justify-end">
            <button onClick={leftClick} css={tabbedSlider.button.responsive} type="submit">
              <ChevronLeftIcon css={tabbedSlider.button.icon} />
            </button>
            <button onClick={rightClick} css={tabbedSlider.button.responsive} type="submit">
              <ChevronRightIcon css={tabbedSlider.button.icon} />
            </button>
          </div>
        </div>
      </Container>
      <button onClick={rightClick} tw="pr-5" css={tabbedSlider.button.base} type="submit">
        <ChevronRightIcon css={tabbedSlider.button.icon} />
        <span css={tabbedSlider.button.right}>{current !== slides.length - 1 ? slides[current + 1].title : slides[0].title}</span>
      </button>
    </div>
  )
}

TabbedSlider.propTypes = {
  slides: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
}

ImageColumn.propTypes = {
  image: PropTypes.object,
}
TextColumn.propTypes = {
  description: PropTypes.string,
  careType: PropTypes.string,
  title: PropTypes.string,
  ageGroup: PropTypes.string,
  link: PropTypes.string,
}
Slide.propTypes = {
  link: PropTypes.string,
  description: PropTypes.string,
  careType: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
  ageGroup: PropTypes.string,
}

export default TabbedSlider
