import tw, { css } from 'twin.macro'

export const wrapper = tw`w-4/5 tablet:w-full`

export const name = {
  base: tw`flex mt-10`,
  img: [
    tw`object-cover mr-5 rounded-full`,
    css`
      width: 80px;
      height: 80px;
    `,
  ],
  text: tw`my-auto font-bold text-purple`,
}
