import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ArrowLongRightIcon, ArrowLongLeftIcon } from '@heroicons/react/24/solid'
import { slider, content } from './Testimonials.styles'
import Container from '../../components/Container/Container'
import Testimonial from '../../components/Testimonial/Testimonial'

function structureArr(data) {
  const newArr = []
  for (let index = 0; index < data.length; index += 2) {
    const element = data[index]
    newArr.push([element, data[index + 1]])
  }

  return newArr
}

const Testimonials = ({ title, testimonialList, titleImage }) => {
  const isBrowser = () => typeof window !== 'undefined'
  const breakpoint = 820
  const [count, setCount] = useState(0)
  const [parsedData, setParsedData] = useState(
    isBrowser() && window.innerWidth > breakpoint ? structureArr(testimonialList) : testimonialList
  )
  const imageObject = getImage(titleImage && titleImage.gatsbyImage)

  useEffect(() => {
    const handleResize = () => {
      // viewport size = desktop
      if (isBrowser() && window.innerWidth > breakpoint) {
        setParsedData(structureArr(testimonialList))
      }
      // vieport size < tablet
      else {
        setParsedData(testimonialList)
      }
    }
    isBrowser() && window.addEventListener('resize', handleResize)
    return () => {
      isBrowser() && window.removeEventListener('resize', handleResize)
    }
  })

  function rightClick() {
    setCount(count === parsedData.length - 1 ? 0 : count + 1)
  }

  function leftClick() {
    setCount(count === 0 ? parsedData.length - 1 : count - 1)
  }

  return (
    <Container variant="wide" css={content.wrapper}>
      {/* TITLE + LOGO */}
      <div css={content.headingContainer.base}>
        {titleImage && imageObject && <GatsbyImage image={imageObject} css={content.headingContainer.img} alt={titleImage.altText} />}
        <h2 css={content.headingContainer.heading}>{title}</h2>
      </div>
      <div css={slider.wrapper}>
        {/* LEFT CLICK */}
        <button onClick={leftClick} type="submit" alt="Left Arrow" aria-label="Left arrow">
          <ArrowLongLeftIcon css={slider.icon} />
        </button>
        {/* RIGHT CLICK */}
        <button onClick={rightClick} tw="ml-4" type="submit" aria-label="Right arrow">
          <ArrowLongRightIcon css={slider.icon} />
        </button>
      </div>
      <div css={slider.testimonials}>
        {parsedData.map((data, index) => (
          <div key={index}>
            {Array.isArray(data) ? (
              count === index && (
                <div css={slider.testimonials}>
                  <Testimonial
                    key={data[0].id}
                    image={data[0].featuredImage && data[0].featuredImage.node}
                    author={data[0].testimonials.author}
                    testimonial={data[0].testimonials.testimonial}
                  />
                  {data[1] && (
                    <Testimonial
                      key={data[1].id}
                      image={data[1].featuredImage && data[1].featuredImage.node}
                      author={data[1].testimonials.author}
                      testimonial={data[1].testimonials.testimonial}
                    />
                  )}
                </div>
              )
            ) : (
              <div css={slider.testimonials}>
                {count === index && (
                  <Testimonial
                    key={data.id}
                    image={data.featuredImage && data.featuredImage.node}
                    author={data.testimonials.author}
                    testimonial={data.testimonials.testimonial}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </Container>
  )
}

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  titleImage: PropTypes.object,
  testimonialList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
}

export default Testimonials
